import authDefaults from '@/constants/auth-constants';
import { useUserStore } from '@/stores/userStore';

export default defineNuxtRouteMiddleware((to: any, from: any) => {
    const userStore = useUserStore();
    const { ensureDataIntegrity, endSession, tokenExists } = useAuth();

    if (!ensureDataIntegrity()) {
        endSession(false);
        return navigateTo(authDefaults.logoutRedirectTo);
    }

    if (!isRouteAccessible(to, userStore, tokenExists)) {
        if (!userStore.isAuthenticated)
            return navigateTo(authDefaults.unauthorizedRedirectTo);
        else
            return navigateTo(authDefaults.forbiddenRedirectTo);
    }
});

function isRouteAccessible(route: any, userStore: any, tokenExists: any) {
    if (route.meta && route.meta.auth) {
        if (!userStore.isAuthenticated)
            return false;

        if (!tokenExists())
            return false;

        if (route.meta.auth.roles)
            return userStore.isInRole(route.meta.auth.roles);
    }

    return true;
}